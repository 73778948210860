import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "content"];

  connect() {
    if (this.hasToggleTarget) {
      this.watchToggle();
    }

    if (this.toggleTarget.tagName === "INPUT" && this.toggleTarget.checked) {
      this.toggleContent();
    }
  }

  watchToggle() {
    this.toggleTarget.addEventListener("click", () => {
      this.toggleContent();
    });
  }

  toggleContent() {
    this.contentTarget.classList.toggle("hidden");
  }
}
